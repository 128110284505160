(($) => {
  //$('.collapse').collapse();

  const setAccordionInnerCollapse = ($el) => {
    const height = $el.height();
    if (height && !$el.hasClass("collapse")) {
      $el.addClass("collapse")
      if (height > 700) {
        $el.addClass("collapsing");
      } else {
        $el.addClass("show");
      }
    }
  }

  $('.component-donors-list').find('.accordion-body .body-collapse').each(function() {
    setAccordionInnerCollapse($(this));
  });

  $('.component-donors-list').find('.accordion-collapse').on('shown.bs.collapse', function() {
    setAccordionInnerCollapse($(this).find('.accordion-body .body-collapse'));
  })
})(jQuery)